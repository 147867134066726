import { type ReactNode } from 'react';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useDeviceCheckContext } from '../Device/Check';
import { VideoEffectsSettingsCore } from '../VideoEffectsSettings/Core';
import { PreviewVideoEffectsSettingsProvider } from '../VideoEffectsSettings/Provider';
import { useVideoEffectsSettingsStore } from '../VideoEffectsSettings/Storage';
import {
  VesLocalProfileKey,
  type VideoEffectsSettings,
} from '../VideoEffectsSettings/types';

type PanelProps = {
  mediaStream: MediaStream | null;
  initialSettings: VideoEffectsSettings;
  onSave?: (values: VideoEffectsSettings) => Promise<void>;
  introOutroSettings?: boolean;
  title?: ReactNode;
  stagePodiumSettings?: boolean | 'stage' | 'podium';
  headerAccessory?: ReactNode;
  settingsEditable?: boolean;
};

export function VideoEffectsSettingsVenueSettingsPanel(props: PanelProps) {
  return (
    <PreviewVideoEffectsSettingsProvider
      initialSettings={props.initialSettings}
    >
      <VideoEffectsSettingsCore
        streamOrSrc={props.mediaStream}
        targetVideoProfile={getFeatureQueryParamArray('host-video-profile')}
        muted
        autoplay
        controls={false}
        initialEditingDisabled={true}
        settingsEditable={props.settingsEditable}
        initialSettings={props.initialSettings}
        onSave={async (values) => {
          await props.onSave?.(values);
        }}
        deviceSelect
        title={props.title}
        introOutroSettings={props.introOutroSettings}
        stagePodiumSettings={props.stagePodiumSettings}
        headerAccessory={props.headerAccessory}
      />
    </PreviewVideoEffectsSettingsProvider>
  );
}

export function HostVESVenueSettingsPanel(): JSX.Element | null {
  const { videoMediaStream } = useDeviceCheckContext();
  const store = useVideoEffectsSettingsStore();

  return (
    <VideoEffectsSettingsVenueSettingsPanel
      introOutroSettings
      stagePodiumSettings
      mediaStream={videoMediaStream}
      initialSettings={store.getSettings(VesLocalProfileKey.Default)}
      settingsEditable={true}
      onSave={async (values) => {
        store.replaceFromExternal(VesLocalProfileKey.Default, values);
      }}
    />
  );
}
