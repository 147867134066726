export function GlitterIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-5.5 h-5.5 fill-current'}
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7.6831 6.48889C7.40561 8.37515 6.52813 10.1226 5.18187 11.4708C3.83374 12.8189 2.08626 13.6983 0.199951 13.9758C2.08621 14.2533 3.8337 15.1289 5.18187 16.4771C6.53005 17.8252 7.40749 19.5727 7.6831 21.459C7.9606 19.5727 8.84 17.8271 10.1881 16.479C11.5381 15.1309 13.2837 14.2534 15.172 13.9759C13.2838 13.6984 11.5382 12.819 10.1881 11.4709C8.84 10.1227 7.96251 8.37524 7.6831 6.48894V6.48889Z' />
      <path d='M15.1717 0.54126C14.9017 2.36003 13.4729 3.78875 11.6523 4.05875C13.473 4.32875 14.9017 5.75752 15.1717 7.57811C15.4398 5.76121 16.8629 4.33249 18.6798 4.05875C16.8629 3.785 15.4398 2.35811 15.1717 0.54126Z' />
      <path d='M1.53872 5.74443C1.64372 5.05069 2.18936 4.50694 2.8831 4.40566C2.18749 4.30254 1.64187 3.7569 1.53872 3.06128C1.43934 3.7569 0.895615 4.30251 0.199951 4.40566C0.893695 4.50504 1.43744 5.05069 1.53872 5.74443Z' />
      <path d='M21.8001 12.631C21.1064 12.5316 20.5607 11.986 20.4613 11.2922C20.3582 11.986 19.8107 12.5316 19.1169 12.631C19.8107 12.736 20.3563 13.2816 20.4613 13.9754C20.5607 13.2816 21.1064 12.7342 21.8001 12.631Z' />
    </svg>
  );
}
