import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { EnumsUserVesProfileKey } from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { err2s } from '../../utils/common';
import { releaseMediaStream, removeAllTracks } from '../../utils/media';
import { Modal } from '../common/Modal';
import { type Option } from '../common/Utilities';
import { useDeviceAPI, useDeviceState } from '../Device';
import {
  DeviceCheck as DeviceCheckCore,
  DeviceCheckContextProvider,
  useDeviceCheckContext,
} from '../Device/Check';
import {
  useMustVideoEffectsMixer,
  useMustVirtualBackgroundMixer,
} from '../Device/video-stream-mixer';
import { VBGSettingsButton } from '../Device/VirtualBackgroundSettings';
import { HostIcon } from '../icons/HostIcon';
import { Loading } from '../Loading';
import { useUserContext } from '../UserContext';
import { useVideoEffectsSettingsStore } from '../VideoEffectsSettings/Storage';
import { VideoEffectsSettingsDeviceCheckPanel } from '../VideoEffectsSettingsPanels/DeviceCheck';
import { VideoEffectsSettingsVenueSettingsPanel } from '../VideoEffectsSettingsPanels/Venue';
import { useCohostMixerEnabled } from './CohostPositionManagerProvider';

// The team asked to re-enable the editing feature.
function isDebugging() {
  // return getFeatureQueryParamArray('debug-tools') !== 'disabled';
  return true;
}

function VesProfileDropdown(props: {
  vesProfileKey: EnumsUserVesProfileKey;
  setVesProfileKey: (key: EnumsUserVesProfileKey) => void;
  disabled: boolean;
}) {
  const options = useInstance(() => [
    {
      label: 'Cohost Solo',
      value: EnumsUserVesProfileKey.UserVesProfileKeyCohostSolo,
    },
    {
      label: 'Cohost & Player on Stage',
      value: EnumsUserVesProfileKey.UserVesProfileKeyCohostInterview,
    },
  ]);
  return (
    <section className='mb-6'>
      <div className='font-bold text-center mb-4'>Settings Profiles</div>
      <div className='flex items-center gap-3'>
        <HostIcon className='w-5 h-5 fill-current' />
        <Select<Option<EnumsUserVesProfileKey>>
          options={options}
          isSearchable={false}
          className='select-box flex-grow'
          classNamePrefix='select-box'
          value={options.find((opt) => opt.value === props.vesProfileKey)}
          onChange={(opt) => {
            if (!opt) return;
            props.setVesProfileKey(opt.value);
          }}
          isDisabled={props.disabled}
        />
      </div>
    </section>
  );
}

function DeviceCheckInternal(props: { handleClose?: () => void }) {
  const { updateUserStates } = useUserContext();
  const { videoMediaStream } = useDeviceCheckContext();
  const { activeVideoInputDeviceOption: did } = useDeviceState();

  const deviceAPI = useDeviceAPI();
  const mixer = deviceAPI.mixer;
  const vbgMixer = useMustVirtualBackgroundMixer(mixer);
  const vesMixer = useMustVideoEffectsMixer(mixer);
  const store = useVideoEffectsSettingsStore();
  const [outputStream, setOutputMediaStream] = useState<MediaStream | null>(
    null
  );
  const [vesProfileKey, setVesProfileKey] = useState(
    EnumsUserVesProfileKey.UserVesProfileKeyCohostSolo
  );

  const {
    call: loadVesSettings,
    state: { state, error },
  } = useLiveAsyncCall(async () => {
    await store.load();
  });

  useLayoutEffect(() => {
    loadVesSettings();
  }, [loadVesSettings]);

  useLayoutEffect(() => {
    async function exec() {
      const inputTrack = videoMediaStream?.getVideoTracks()[0];
      await mixer.updateCameraTrack(inputTrack ?? null);
      // we only need the camera track (with virtual background) here, the
      // final rendering will be handled by VideoEffectsMediaElement.
      const outputTrack = vbgMixer.virtualBackgroundTrack;
      const s = new MediaStream();
      if (outputTrack) {
        s.addTrack(outputTrack);
      }
      setOutputMediaStream(s);
    }
    exec();
  }, [mixer, vbgMixer, videoMediaStream]);

  const handleContinue = useLiveCallback(async () => {
    await vesMixer.updateVideoEffectsSettings(
      store.getSettings(EnumsUserVesProfileKey.UserVesProfileKeyCohostSolo)
    );
    if (videoMediaStream && did?.value) {
      await deviceAPI.updateSingletonVideoTrack({
        action: 'update',
        input: {
          cameraTrack: videoMediaStream.getVideoTracks()[0],
          deviceId: did.value,
          clone: true,
        },
      });
    } else {
      deviceAPI.log.warn('update singleton video track failed', {
        hasVideoMediaStream: !!videoMediaStream,
        deviceId: did?.value,
      });
    }

    updateUserStates({ joined: true });
    props.handleClose?.();
  });

  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.srcObject = outputStream;
  }, [outputStream]);

  if (state.isRunning || error) {
    return (
      <div className='w-full h-full flex items-center justify-center text-white'>
        {state.isRunning && <Loading text='Loading your configurations...' />}
        {error && <div className='text-red-002'>{err2s(error)}</div>}
      </div>
    );
  }

  return (
    <VideoEffectsSettingsDeviceCheckPanel
      // We need to specify the key to force the re-rendering because
      // it is a uncontrolled component.
      key={vesProfileKey}
      mediaStream={outputStream}
      onContinue={handleContinue}
      title='Cohost Configuration Settings'
      stagePodiumSettings
      headerAccessory={
        vbgMixer.virtualBackgroundAvailable &&
        isDebugging() && (
          <VBGSettingsButton className='btn-warning min-w-36 min-h-10' />
        )
      }
      settingsEditable={isDebugging()}
      vesProfileKey={vesProfileKey}
      vesProfileDropdown={(editing) => (
        <VesProfileDropdown
          vesProfileKey={vesProfileKey}
          setVesProfileKey={setVesProfileKey}
          disabled={editing}
        />
      )}
    />
  );
}

export function CohostDeviceCheckModal(props: { handleClose?: () => void }) {
  return (
    <DeviceCheckContextProvider>
      <Modal className='w-11/12 h-5/6'>
        <div className={`w-full h-full p-8 overflow-auto scrollbar`}>
          <DeviceCheckInternal {...props} />
        </div>
      </Modal>
    </DeviceCheckContextProvider>
  );
}

function CohostFullVESVenueSettingsPanel() {
  const deviceAPI = useDeviceAPI();
  const mixer = deviceAPI.mixer;
  const vbgMixer = useMustVirtualBackgroundMixer(mixer);
  const vesMixer = useMustVideoEffectsMixer(mixer);
  const [vesSettings, setVesSettings] = useState(
    vesMixer.getVideoEffectsSettings()
  );
  const [track, setTrack] = useState(vbgMixer.virtualBackgroundTrack);
  const outputStreamRef = useRef(new MediaStream());

  useEffect(() => {
    return vbgMixer.on('virtual-background-track-updated', (t) => setTrack(t));
  }, [vbgMixer]);

  useEffect(() => {
    releaseMediaStream(outputStreamRef.current);
    removeAllTracks(outputStreamRef.current);
    if (track) {
      // The underlying _VideoEffectsMediaElement_ will release the
      // UnplayableVideo when the component unmounted. We need to clone the
      // track here to avoid the track in mixer being released.
      outputStreamRef.current.addTrack(track.clone());
    }
  }, [track]);

  // Sync the video effects settings back to mixer
  useEffect(() => {
    return () => {
      vesMixer.updateVideoEffectsSettings(vesSettings);
    };
  }, [vesSettings, vesMixer]);

  return (
    <VideoEffectsSettingsVenueSettingsPanel
      mediaStream={outputStreamRef.current}
      initialSettings={vesSettings}
      title='Cohost Configuration Settings'
      stagePodiumSettings
      settingsEditable={true}
      headerAccessory={
        vbgMixer.virtualBackgroundAvailable && (
          <VBGSettingsButton className='btn-warning min-w-36 min-h-10' />
        )
      }
      onSave={async (values) => {
        setVesSettings(values);
      }}
    />
  );
}

export function CohostVESVenueSettingsPanel() {
  const mixerEnabled = useCohostMixerEnabled();
  if (mixerEnabled) return <CohostFullVESVenueSettingsPanel />;
  return <DeviceCheckCore layout='col' />;
}
