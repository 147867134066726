import { useEffect, useState } from 'react';
import { Controller, get, useForm, type UseFormWatch } from 'react-hook-form';

import { EnumsUserSettingsGroupKey } from '@lp-lib/api-service-client/public';

import { useUserSettingsAnalytics } from '../../analytics/userSettings';
import {
  type GroupSettings,
  OrganizerUtils,
  type PrivacySettings,
} from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import { getInitials } from '../../utils/string';
import {
  InitialsJoyCapture,
  MyJoyCapture,
  useJoyCaptureRenderer,
} from '../JoyCapture';
import { Loading } from '../Loading';
import { useUser } from '../UserContext';
import { usePrivacySettings } from './usePrivacySettings';

const joyCaptureSample = getStaticAssetPath('images/joy-capture-sample.gif');

export const Privacy = (): JSX.Element => {
  const user = useUser();
  const { privacySettings, isLoading, update } = usePrivacySettings();

  if (!user.id) return <></>;
  if (isLoading && privacySettings === undefined) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  const fallbackInitials = getInitials(
    user.organizer ? OrganizerUtils.GetFullName(user.organizer) : user.username
  );

  return (
    <div className='w-full flex flex-col gap-7.5'>
      <section className='w-130'>
        <JoyCaptureSettings
          initialData={privacySettings?.value ?? undefined}
          update={update}
          fallbackInitials={fallbackInitials}
        />
      </section>
    </div>
  );
};

interface JoyCaptureSettingsFormData {
  allowJoyCaptures: boolean;
}

function useTrackFormModifications(
  watch: UseFormWatch<JoyCaptureSettingsFormData>
) {
  const analytics = useUserSettingsAnalytics();
  useEffect(() => {
    const sub = watch((values, { name }) => {
      if (!name) return;

      const value: unknown = get(values, String(name), undefined);

      analytics.trackUserSettingsModified({
        groupKey: EnumsUserSettingsGroupKey.UserSettingsGroupKeyPrivacy,
        field: String(name),
        value,
      });
    });
    return () => sub.unsubscribe();
  }, [watch, analytics]);
}

function JoyCaptureSettings(props: {
  initialData?: PrivacySettings;
  update: (
    privacySettings: Partial<PrivacySettings>
  ) => Promise<GroupSettings<PrivacySettings> | null>;
  fallbackInitials: string;
}): JSX.Element {
  const defaultValues = {
    allowJoyCaptures: props.initialData?.allowJoyCaptures ?? true,
  };

  const analytics = useUserSettingsAnalytics();
  const { register, control, reset, handleSubmit, watch } =
    useForm<JoyCaptureSettingsFormData>({
      mode: 'onSubmit',
      defaultValues,
    });
  useTrackFormModifications(watch);
  const renderer = useJoyCaptureRenderer();
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);

  const onSubmit = handleSubmit(async (data: JoyCaptureSettingsFormData) => {
    analytics.trackUserSettingsSaved(
      EnumsUserSettingsGroupKey.UserSettingsGroupKeyPrivacy
    );
    const updatedPrivacySettings = {
      allowJoyCaptures: data.allowJoyCaptures,
    };

    setUpdating(true);
    try {
      await props.update(updatedPrivacySettings);
    } finally {
      setUpdating(false);
    }
    setEditing(false);
  });

  const onCancel = () => {
    reset(defaultValues);
    setEditing(false);
  };

  return (
    <div className='w-full text-white'>
      <div>
        <h2 className='font-bold text-base mb-2.5'>In-Game Photos</h2>
      </div>

      <Controller<JoyCaptureSettingsFormData>
        control={control}
        name='allowJoyCaptures'
        render={({ field: { value } }) => {
          return (
            <div className='flex items-center gap-3'>
              <div className='relative w-20 h-20 flex-none overflow-hidden'>
                {value ? (
                  <>
                    <MyJoyCapture
                      styles={{
                        size: 'w-20 h-20 flex-none',
                      }}
                      renderer={renderer}
                      fallbackImageSrc={joyCaptureSample}
                      noInitials
                    />
                    <div className='absolute top-7 left-0 right-0 py-1 bg-black text-center font-bold text-xs opacity-20'>
                      Example
                    </div>
                  </>
                ) : (
                  <InitialsJoyCapture
                    initials={props.fallbackInitials}
                    styles={{
                      size: 'w-20 h-20 flex-none',
                    }}
                  />
                )}
              </div>
              <div className='text-sms text-icon-gray'>
                We collect joyful moments in each player’s game experience (like
                the example on the left) and turn those moments into animated
                gifs that may be displayed within your organization.{' '}
                <span className='font-bold'>
                  Captured joyful images will never be displayed outside of your
                  organization’s account.
                </span>
              </div>
            </div>
          );
        }}
      />

      <form onSubmit={onSubmit} className={`w-full flex flex-col gap-2`}>
        <div className='mt-6 text-sms flex gap-3.5'>
          <input
            type='checkbox'
            className='checkbox-dark w-5 h-5 disabled:cursor-default flex-none'
            disabled={!editing}
            {...register('allowJoyCaptures')}
          />
          Allow Luna Park to capture and display my joyful moments in my
          organization’s account.
          <div className='mr-14'>
            <button
              type='button'
              onClick={() => setEditing(true)}
              className={`btn text-primary ${
                editing ? 'invisible pointer-events-none' : ''
              }`}
            >
              Edit
            </button>
          </div>
        </div>

        {editing && (
          <div className='mt-4 w-full flex gap-4'>
            <button
              type='button'
              onClick={onCancel}
              className='btn-secondary w-33 h-10'
            >
              Cancel
            </button>

            <button
              type='submit'
              className='btn-primary w-33 h-10 flex justify-center items-center'
              disabled={updating}
            >
              {updating && <Loading text='' containerClassName='mr-2' />}
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
