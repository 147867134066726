import { NavLink } from '@remix-run/react';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/game';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { MediaUploader } from '../MediaUploader/MediaUploader';
import { SwitcherControlled } from '../Switcher';
import { isOrgMember, useUser } from '../UserContext';
import { getVenueSlug, useMyVenue, useVenueAPI } from '../Venue/VenueProvider';
import { ItemContainer } from './ItemContainer';

export const MyVenue = (): JSX.Element => {
  const guestControlEnabled = useFeatureQueryParam(
    'venue-settings-guest-control'
  );
  return (
    <div className='w-full flex flex-col gap-7.5'>
      <Organization />
      <VenueDisplayName />
      <MyVenueURL />
      <VenueBackground />
      <ShareVenueControl />
      {guestControlEnabled && <GuestUserControl />}
      <GoToMyVenueButton />
    </div>
  );
};

const Organization = (): JSX.Element | null => {
  const user = useUser();
  if (!user.organizer?.organization) return null;

  return (
    <ItemContainer
      title='Organization'
      value={user.organizer.organization.name}
      editable={false}
    />
  );
};

const VenueDisplayName = (): JSX.Element | null => {
  const myVenue = useMyVenue();
  const api = useVenueAPI();
  if (!myVenue) return null;

  return (
    <ItemContainer
      title='Venue Display Name'
      value={myVenue.displayName}
      editable
      onSave={(displayName) => api.persist({ displayName })}
      minLength={3}
      maxLength={25}
    />
  );
};

const MyVenueURL = (): JSX.Element | null => {
  const myVenue = useMyVenue();
  const api = useVenueAPI();
  if (!myVenue) return null;

  const prefix = window.location.host + '/venue/';
  const venueName = getVenueSlug(myVenue);

  const remainDays = myVenue.nameModifiedAt
    ? 30 -
      Math.round(
        (new Date(Date.now()).getTime() -
          new Date(myVenue.nameModifiedAt).getTime()) /
          (1000 * 3600 * 24)
      )
    : 0;

  const hint = (
    <div>
      {remainDays > 0 && (
        <p className='text-red-005 font-medium text-3xs'>{`Venue Name can be changed again in ${remainDays} days`}</p>
      )}
      <p className='text-sms font-normal text-secondary'>
        Venue Names must be 4 to 25 characters and unique with no spaces or
        special characters (A-Z and numbers). Venue Names can only be changed
        once every 30 days!
      </p>
    </div>
  );

  return (
    <ItemContainer
      title='My Venue URL'
      prefix={prefix}
      value={venueName}
      copyable
      editable
      hint={hint}
      onSave={(name) => api.persist({ name })}
      formClassName='w-140'
      minLength={3}
      maxLength={25}
    />
  );
};

const VenueBackground = (): JSX.Element | null => {
  const myVenue = useMyVenue();
  const api = useVenueAPI();
  if (!myVenue) return null;

  const onMediaDelete = () => {
    api.persist({ backgroundMediaId: '' });
  };

  const onUploadSuccess = (media: Media) => {
    api.persist({ backgroundMediaId: media.id });
  };

  return (
    <div>
      <label className='mb-2 text-white font-bold'>Event Theme</label>
      <div className='w-85 h-full relative flex flex-col items-center self-start'>
        <MediaUploader
          media={myVenue.background}
          scene={EnumsMediaScene.MediaSceneVenueBackground}
          onUploadSuccess={onUploadSuccess}
          onMediaDelete={onMediaDelete}
          objectFit='object-cover'
        />
      </div>
    </div>
  );
};

const GoToMyVenueButton = (): JSX.Element | null => {
  const user = useUser();
  const myVenue = useMyVenue();
  if (!myVenue) return null;

  return (
    <button type='button'>
      <NavLink
        to={
          isOrgMember(user)
            ? `/venue/${getVenueSlug(myVenue)}`
            : `/host/venue/${getVenueSlug(myVenue)}`
        }
        reloadDocument
        className={({ isActive }) =>
          isActive
            ? 'hidden'
            : `w-45 h-12.5 text-secondary bg-secondary border border-secondary rounded-xl flex justify-center items-center`
        }
      >
        Go to My Venue
      </NavLink>
    </button>
  );
};

function ShareVenueControl(): JSX.Element | null {
  const user = useUser();
  const myVenue = useMyVenue();
  const api = useVenueAPI();

  if (!user.organizer || !myVenue) return null;

  return (
    <div className='w-80 flex flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-white font-bold'>Share Venue Control</span>
        <SwitcherControlled
          name='shareControl'
          className=''
          checked={myVenue.shareControl}
          onChange={(checked: boolean): void => {
            api.persist({ shareControl: checked });
          }}
        />
      </div>
      <div className='text-icon-gray text-3xs font-medium mt-2'>
        When enabled, games in your venue can be started by the first person to
        join the venue whether that is you or someone else.
      </div>
    </div>
  );
}

function GuestUserControl(): JSX.Element | null {
  const user = useUser();
  const myVenue = useMyVenue();
  const api = useVenueAPI();
  if (!user.organizer || !myVenue) return null;

  return (
    <div className='w-80 flex flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-white font-bold'>Guest User Control</span>
        <SwitcherControlled
          name='guestUserControl'
          className=''
          checked={myVenue.guestEnabled}
          onChange={(checked: boolean): void => {
            api.persist({ guestEnabled: checked });
          }}
        />
      </div>
      <div className='text-icon-gray text-3xs font-medium mt-2'>
        Allows Players to access the Venue without logging in.
      </div>
    </div>
  );
}
