import { useLocation, useNavigate } from '@remix-run/react';
import React from 'react';

import { safeWindowReload } from '../../logger/logger';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { useUserContext } from '../UserContext';
import { buildSearchParamsWithRedirectToAsString } from './hooks';

interface LogoutButtonProps {
  className?: string;
}

export const LogoutButton = (props: LogoutButtonProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { resetUser } = useUserContext();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const handleClick = async (event: React.MouseEvent) => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalText className='text-2xl font-medium'>
          Are you sure you want to log out?
        </ConfirmCancelModalText>
      ),
      confirmBtnLabel: 'Log out',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'confirm',
    });
    if (response.result !== 'confirmed') return;

    resetUser();

    const isAudiencePage = location.pathname.startsWith('/venue');
    if (!isAudiencePage) {
      navigate(
        {
          pathname: '/login',
          search: buildSearchParamsWithRedirectToAsString(window.location.href),
        },
        {
          replace: true,
        }
      );
    } else {
      // guest user enabled:
      //  stay on the audience page after logout
      // guest user disabled:
      //  go to login page after logout (handled by UserRequired in Audience page)
      safeWindowReload();
    }
    event.stopPropagation();
  };

  return (
    <button
      type='button'
      className={`btn flex justify-center items-center ${props.className}`}
      onClick={handleClick}
    >
      Log out
    </button>
  );
};
