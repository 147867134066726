import { useEffect, useMemo, useState } from 'react';

import { useUserSettingsAnalytics } from '../../analytics/userSettings';
import { useMyInstance } from '../../hooks/useMyInstance';
import { ClientTypeUtils, RoleUtils } from '../../types';
import { OrganizerRoleUtils } from '../../types/organization';
import { LogoutButton } from '../Access';
import { CohostVESVenueSettingsPanel } from '../Cohost/CohostConfiguration';
import {
  DeviceCheck as DeviceCheckCore,
  DeviceCheckContextProvider,
} from '../Device/Check';
import { AccountIcon } from '../icons/AccountIcon';
import { BillingIcon } from '../icons/BillingIcon';
import { CameraIcon } from '../icons/CameraIcon';
import { GlitterIcon } from '../icons/Glitter';
import { GlobeIcon } from '../icons/GlobeIcon';
import { GreenScreenIcon } from '../icons/GreenScreenIcon';
import { LockIcon } from '../icons/LockIcon';
import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { SettingIcon } from '../icons/SettingIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { VenueIcon } from '../icons/VenueIcon';
import { LiteModeVenueSettings } from '../LiteMode';
import {
  OrganizationDetailsMembers,
  OrganizationSettings,
} from '../Organization/Details';
import { OrgConnection } from '../Organization/OrgConnection';
import { useAmICohost } from '../Player';
import { useUser } from '../UserContext';
import { HostVESVenueSettingsPanel } from '../VideoEffectsSettingsPanels/Venue';
import { Account } from './Account';
import { BillingSettings } from './Billing';
import { Language } from './Language';
import { Notifications } from './Notifications';
import { OrganizationPanel } from './Org';
import { Privacy } from './Privacy';
import { Sessions } from './Session';
import { type Menu, MenuKey } from './types';
import { MyVenue } from './Venue';

const menuMap: { [key in MenuKey]: Menu } = {
  [MenuKey.Account]: {
    key: MenuKey.Account,
    title: 'My Account',
    icon: <AccountIcon className='w-4 h-4 fill-current' />,
    content: () => <Account />,
  },
  [MenuKey.VideoAudio]: {
    key: MenuKey.VideoAudio,
    title: 'Video & Audio',
    icon: <CameraIcon className='w-4 h-4 fill-current' />,
    content: () => (
      <DeviceCheckContextProvider>
        <DeviceCheckCore layout='col' />
      </DeviceCheckContextProvider>
    ),
  },
  [MenuKey.HostConfiguration]: {
    key: MenuKey.HostConfiguration,
    title: 'Host Configuration',
    disableTitleInBody: true,
    icon: <GreenScreenIcon className='w-4 h-4 fill-current' />,
    content: () => (
      <DeviceCheckContextProvider>
        <HostVESVenueSettingsPanel />
      </DeviceCheckContextProvider>
    ),
  },
  [MenuKey.CohostCongifuration]: {
    key: MenuKey.CohostCongifuration,
    title: 'Cohost Configuration',
    disableTitleInBody: true,
    icon: <GreenScreenIcon className='w-4 h-4 fill-current' />,
    content: () => (
      <DeviceCheckContextProvider>
        <CohostVESVenueSettingsPanel />
      </DeviceCheckContextProvider>
    ),
  },
  [MenuKey.MyVenue]: {
    key: MenuKey.MyVenue,
    title: 'My Venue',
    icon: <VenueIcon className='w-4 h-4 fill-current' />,
    content: () => <MyVenue />,
  },
  [MenuKey.GameSessions]: {
    key: MenuKey.GameSessions,
    title: 'Analytics',
    disableTitleInBody: true,
    icon: <ScoreboardIcon className='w-4 h-4 fill-current' />,
    content: (props) => <Sessions {...props} />,
  },
  [MenuKey.OrgMembers]: {
    key: MenuKey.OrgMembers,
    title: 'Manage Members',
    disableTitleInBody: true,
    icon: <TeamIcon className='w-4 h-4 fill-current' />,
    content: (props) => (
      <OrganizationPanel user={props.user}>
        <OrganizationDetailsMembers />
      </OrganizationPanel>
    ),
  },
  [MenuKey.OrgSettings]: {
    key: MenuKey.OrgSettings,
    title: 'Organization Settings',
    disableTitleInBody: true,
    icon: <SettingIcon className='w-4 h-4 fill-current' />,
    content: (props) => (
      <OrganizationPanel user={props.user}>
        <OrganizationSettings />
      </OrganizationPanel>
    ),
  },
  [MenuKey.LiteMode]: {
    key: MenuKey.LiteMode,
    title: 'Lite Mode',
    icon: <GlitterIcon className='w-4 h-4 fill-current' />,
    content: () => <LiteModeVenueSettings />,
  },
  [MenuKey.Privacy]: {
    key: MenuKey.Privacy,
    title: 'Privacy',
    icon: <LockIcon className='w-4 h-4 fill-current' />,
    content: () => <Privacy />,
  },
  [MenuKey.Language]: {
    key: MenuKey.Language,
    title: 'Language Settings',
    icon: <GlobeIcon className='w-4 h-4 fill-current' />,
    content: () => <Language />,
  },
  [MenuKey.Notifications]: {
    key: MenuKey.Notifications,
    title: 'Notifications',
    icon: <MegaphoneIcon className='w-4 h-4 fill-current' />,
    content: () => <Notifications />,
  },
  [MenuKey.Billing]: {
    key: MenuKey.Billing,
    title: 'Billing',
    disableTitleInBody: true,
    icon: <BillingIcon className='w-4 h-4 fill-current' />,
    content: () => <BillingSettings />,
  },
};

function getMenuByKey(key: MenuKey | undefined): Menu | undefined {
  if (!key) return;
  return menuMap[key];
}

function SettingsInternal(props: {
  enabledMenuKeys: MenuKey[];
  initialMenuKey?: MenuKey;
  selectedMenuKey?: MenuKey;
  onMenuSelected?: (key: MenuKey) => void;
}) {
  const { enabledMenuKeys, initialMenuKey, selectedMenuKey, onMenuSelected } =
    props;
  const menus = useMemo(
    () =>
      enabledMenuKeys.map((k) => getMenuByKey(k)).filter((m): m is Menu => !!m),
    [enabledMenuKeys]
  );
  const user = useUser();
  const [uncontrolledMenu, setUncontrolledMenu] = useState<Menu | undefined>(
    getMenuByKey(initialMenuKey ?? enabledMenuKeys[0])
  );
  const activeMenu = useMemo(() => {
    const isControlled = selectedMenuKey !== undefined;
    return isControlled ? getMenuByKey(selectedMenuKey) : uncontrolledMenu;
  }, [selectedMenuKey, uncontrolledMenu]);
  const analytics = useUserSettingsAnalytics();

  useEffect(() => {
    if (!activeMenu?.key) return;
    // Emit the event whenever the key changes, including when the component
    // first mounts
    analytics.trackUserSettingsPanelViewed(activeMenu.key);
  }, [activeMenu?.key, analytics]);

  if (!user) return null;

  const handleChangeActiveMenu = (m: Menu) => {
    setUncontrolledMenu(m);
    onMenuSelected?.(m.key);
  };

  return (
    <div className='w-full h-full bg-black overflow-hidden flex flex-row'>
      <aside className='w-68 h-full p-2 bg-[#101012] text-white text-sm flex flex-col justify-between pb-2 flex-shrink-0'>
        <ul>
          {menus.map((m) => {
            return (
              <li
                key={m.key}
                className={`${
                  activeMenu?.key === m.key
                    ? 'bg-lp-gray-002 rounded-xl border border-black'
                    : 'hover:btn-primary'
                } mb-1 px-4 py-2.5 flex items-center btn cursor-pointer`}
                onClick={() => {
                  handleChangeActiveMenu(m);
                }}
              >
                {m.icon}
                <span className='ml-2'>{m.title}</span>
              </li>
            );
          })}
        </ul>

        <div className='w-full flex flex-col items-center gap-3'>
          {user.organizer?.organization && (
            <OrgConnection org={user.organizer.organization} />
          )}
          <LogoutButton className='w-full h-11 text-icon-gray' />
        </div>
      </aside>

      {activeMenu && (
        <div className='w-full p-6 h-full overflow-y-auto scrollbar'>
          {!activeMenu.disableTitleInBody && (
            <header className='text-white text-2xl mb-6'>
              {activeMenu.title}
            </header>
          )}
          {activeMenu.content({
            user: user,
          })}
        </div>
      )}
    </div>
  );
}

function useMenuKeys() {
  const user = useUser();
  const orgManageable = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);
  const isAdmin = RoleUtils.isAdmin(user);
  const me = useMyInstance();

  const isHost = ClientTypeUtils.isHost(me);
  const isCohost = useAmICohost();
  const hasInstance = Boolean(me?.clientId);

  return useMemo(() => {
    const menuKeys: MenuKey[] = [
      MenuKey.Account,
      MenuKey.Privacy,
      MenuKey.Notifications,
    ];

    if (hasInstance) {
      menuKeys.push(MenuKey.Language);
    }

    if (isHost) {
      menuKeys.push(MenuKey.HostConfiguration);
    } else if (isCohost) {
      menuKeys.push(MenuKey.CohostCongifuration);
    } else {
      menuKeys.push(MenuKey.VideoAudio);
    }
    if (user.venueActivated) {
      menuKeys.push(MenuKey.MyVenue);
    }
    if (isAdmin) {
      menuKeys.push(MenuKey.GameSessions);
    }
    if (orgManageable) {
      menuKeys.push(MenuKey.OrgMembers);
      menuKeys.push(MenuKey.OrgSettings);
      menuKeys.push(MenuKey.Billing);
    }

    if (!isHost && hasInstance) {
      menuKeys.push(MenuKey.LiteMode);
    }

    return menuKeys;
  }, [
    isHost,
    isCohost,
    user.venueActivated,
    isAdmin,
    orgManageable,
    hasInstance,
  ]);
}

export function SettingsModal(props: {
  onClose: () => void;
  initialMenuKey?: MenuKey;
  selectedMenuKey?: MenuKey;
  onMenuSelected?: (key: MenuKey) => void;
}) {
  const enabledMenuKeys = useMenuKeys();
  return (
    <div className='absolute left-0 top-0 z-50 w-full h-full bg-black flex flex-col'>
      <header className='w-full py-2 px-4 flex flex-row justify-between items-center border-b border-gray-800'>
        <div className='flex flex-row justify-between items-center'>
          <p className='text-xl text-white'>Settings</p>
        </div>
        <button
          type='button'
          className='btn-secondary w-34 h-10'
          onClick={props.onClose}
        >
          Close
        </button>
      </header>

      <SettingsInternal enabledMenuKeys={enabledMenuKeys} {...props} />
    </div>
  );
}

export function Settings(props: {
  selectedMenuKey?: MenuKey;
  onMenuSelected?: (key: MenuKey) => void;
}) {
  const enabledMenuKeys = useMenuKeys();
  return <SettingsInternal enabledMenuKeys={enabledMenuKeys} {...props} />;
}
