import { type ReactNode } from 'react';

import { type User } from '../../types/user';
import { OrgProvider } from '../Organization/Details';

export function OrganizationPanel(props: {
  user: User;
  children: ReactNode;
}): JSX.Element | null {
  const { user } = props;
  if (!user.organizer?.organization) return null;
  return (
    <OrgProvider initOrg={user.organizer.organization}>
      <div className='px-2'>{props.children}</div>
    </OrgProvider>
  );
}
