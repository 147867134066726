import { safeWindowReload } from '../../logger/logger';
import { SwitcherControlled } from '../Switcher';
import { useLiteModeEnabled, useLiteModeToggle } from './LiteModeContext';

export function LiteModeVenueSettings(): JSX.Element {
  const toggle = useLiteModeToggle();
  const enabled = useLiteModeEnabled();

  return (
    <div className='w-full h-full text-white flex flex-col gap-5'>
      <p className='text-sm max-w-128'>
        Turning on Lite Mode will disable streaming video and other network
        heavy features, to play the game without choppiness.{' '}
        <em>This change only impacts your view, not your team's.</em>
      </p>

      <div className='max-w-92 flex justify-between'>
        <div className='flex flex-col gap-2 text-white text-base font-bold'>
          Lite Mode {enabled ? 'Enabled' : 'Disabled'}
          <div className='text-3xs text-icon-gray'>
            {enabled
              ? 'Some features will be unavailable'
              : 'All features will load during games you play'}
          </div>
        </div>
        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='toggle-lite-mode'
            checked={enabled}
            onChange={(checked) => {
              toggle(checked, 'settings');
              // Switching to Lite Mode requires a refresh, for now.
              safeWindowReload();
            }}
          />
        </div>
      </div>
    </div>
  );
}
